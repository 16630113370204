import React, { ReactNode, useEffect, useState } from "react";
import useStore from "../../hooks/useStore";
import STORE_KEYS from "../../static/STOREKEY";
import AddressBoxSuggest from "./AddressBoxSuggest";
import GoogleMapLinkAddress from "./GoogleMapLinkAddress";
import ManualAddress, { INPUT_LIST } from "./ManualAddress";

type TSelectionMethodKey = "googleMap" | "manually";
interface ISelectionMethod {
    label: string;
    methodKey: TSelectionMethodKey
    icon: ReactNode
}

const SELECTION_METHOD: ISelectionMethod[] = [
    {
        label: "Fetch from google map link",
        methodKey: "googleMap",
        icon: <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,12A4,4,0,1,0,8,8,4,4,0,0,0,12,12Zm0-6a2,2,0,1,1-2,2A2,2,0,0,1,12,6Zm8.66,3.157-.719-.239A8,8,0,0,0,12,0,7.993,7.993,0,0,0,4.086,9.092a5.045,5.045,0,0,0-2.548,1.3A4.946,4.946,0,0,0,0,14v4.075a5.013,5.013,0,0,0,3.6,4.8l2.87.9A4.981,4.981,0,0,0,7.959,24a5.076,5.076,0,0,0,1.355-.186l5.78-1.71a2.987,2.987,0,0,1,1.573,0l2.387.8A4,4,0,0,0,24,19.021V13.872A5.015,5.015,0,0,0,20.66,9.156ZM7.758,3.762a5.987,5.987,0,0,1,8.484,0,6.037,6.037,0,0,1,.011,8.5L12.7,15.717a.992.992,0,0,1-1.389,0L7.758,12.277A6.04,6.04,0,0,1,7.758,3.762ZM22,19.021a1.991,1.991,0,0,1-.764,1.572,1.969,1.969,0,0,1-1.626.395L17.265,20.2a5.023,5.023,0,0,0-2.717-.016L8.764,21.892a3,3,0,0,1-1.694-.029l-2.894-.9A3.013,3.013,0,0,1,2,18.075V14a2.964,2.964,0,0,1,.92-2.163,3.024,3.024,0,0,1,1.669-.806A8.021,8.021,0,0,0,6.354,13.7l3.567,3.453a2.983,2.983,0,0,0,4.174,0l3.563-3.463a7.962,7.962,0,0,0,1.813-2.821l.537.178A3.006,3.006,0,0,1,22,13.872Z" /></svg>
    },
    {
        label: "Manually",
        methodKey: "manually",
        icon: <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M20.68,1.167l-.021-.007-2.715-.9a5.017,5.017,0,0,0-2.9-.079L9.468,1.8A3.025,3.025,0,0,1,7.42,1.62l-.5-.232A5,5,0,0,0,0,6V18.075a5.013,5.013,0,0,0,3.6,4.8l2.869.9A4.991,4.991,0,0,0,7.964,24,4.592,4.592,0,0,0,9.3,23.819l5.8-1.6a3,3,0,0,1,1.617.013l2.343.676A4,4,0,0,0,24,19.021V5.876A5.01,5.01,0,0,0,20.68,1.167ZM4.176,20.959A3.013,3.013,0,0,1,2,18.075V6a2.953,2.953,0,0,1,1.336-2.5A3,3,0,0,1,5,3a2.9,2.9,0,0,1,1.112.222s.745.319.887.37V21.842ZM9,21.827V3.879a5.105,5.105,0,0,0,1.006-.155L15,2.273V20.2Zm13-2.806a2,2,0,0,1-2.433,1.954L17,20.252V2.075l3.018.979A3.006,3.006,0,0,1,22,5.876Z" /></svg>
    },
];
export const ENTIRE_MANUALLY_ITEMS = [...INPUT_LIST.map(el => el.inputKey), STORE_KEYS.clinicAddress, STORE_KEYS.clinicPhone];



const ClinicAddress: React.FC = () => {
    const { storeError, resetStoreHandler, errorDispatch } = useStore();

    const [addressDetectionMethod, setAddressDetectionMethod] = useState<TSelectionMethodKey | null>(null);


    const selectAddressDetectionMethodHandler = (method: TSelectionMethodKey) => {
        setAddressDetectionMethod(method);
        const requiredItem = method === "manually" ? ENTIRE_MANUALLY_ITEMS : [STORE_KEYS.googleMapLink];
        errorDispatch("specific", requiredItem);
        errorDispatch("generic", []);
    }

    const backToEmptyMethodHandler = () => {
        resetStoreHandler();
        setAddressDetectionMethod(null);
        preventToGoNextStage();
    }

    const preventToGoNextStage = () => errorDispatch("specific", "--PREVENT--");


    useEffect(function setAStageBlockerHandler() {
        // prevent Going To Next Stage Without Any Selection
        preventToGoNextStage();
    }, []);


    return (
        <div className="clinicAddress">
            <div className={`clinicAddress__intro ${addressDetectionMethod ? "clinicAddress__intro--hide" : ""}`}>
                <p className="clinicAddress__introHelper">You can find your Clinic information with these two method :</p>
                <div className={`clinicAddress__error ${storeError.generic.includes("--PREVENT--") ? "clinicAddress__error--show" : ""}`}>
                    <p>You must provide the address of your clinic using one of the following methods!</p>
                </div>
                <div className="clinicAddress__addressBoxSuggestContainer">
                    {
                        SELECTION_METHOD.map((method, i) => (
                            <AddressBoxSuggest
                                onSelect={name => selectAddressDetectionMethodHandler(name)}
                                {...method}
                                key={i} />
                        ))
                    }
                </div>
            </div>
            <div className={`clinicAddress__inputs ${addressDetectionMethod ? "clinicAddress__inputs--show" : ""}`}>
                {
                    addressDetectionMethod && <>
                        <div className="clinicAddress__inputs__header">
                            <div className="clinicAddress__inputs__header__backTrigger" onClick={backToEmptyMethodHandler}>
                                <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M.88,14.09,4.75,18a1,1,0,0,0,1.42,0h0a1,1,0,0,0,0-1.42L2.61,13H23a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1H2.55L6.17,7.38A1,1,0,0,0,6.17,6h0A1,1,0,0,0,4.75,6L.88,9.85A3,3,0,0,0,.88,14.09Z" /></svg>
                                <p>Back</p>
                            </div>
                            <div className="clinicAddress__inputs__header__methodName">
                                <p>Find clinic information : <span>{SELECTION_METHOD.find(el => el.methodKey === addressDetectionMethod)!.label}</span></p>
                            </div>

                        </div>

                        {
                            addressDetectionMethod === "manually"
                                ? <ManualAddress />
                                : <GoogleMapLinkAddress />
                        }
                    </>
                }
            </div>
        </div >
    )
}


export default ClinicAddress;