import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import ThankYou from "../pages/ThanksYou";

const Router : React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="thankyou" element={<ThankYou />} />
            </Routes>
        </BrowserRouter>
    )
}


export default Router;