const stringConvertor = {
    toList(str : string) {
        if(!str) return [];
        return str.split(",")
    },
    toString(list : string[]) {
        return list.join(",")
    }
}

export default stringConvertor;