import useStore from "../../hooks/useStore";
import STORE_KEYS from "../../static/STOREKEY";
import validateGoogleMaoLink from "../../types/validateGoogleMapLink";
import Input from "../Base/Input";



const GoogleMapLinkAddress = () => {
    const { storeValue, storeError, valueDispatch, errorDispatch } = useStore();

    const inputValueChangeHandler = (value: string) => {
        valueDispatch(STORE_KEYS.googleMapLink, value);
        const isAValidGMapLink = validateGoogleMaoLink(value);

        if (isAValidGMapLink) {
            errorDispatch("generic", []);
            errorDispatch("specific", []);
        } else {
            errorDispatch("generic", STORE_KEYS.googleMapLink);
        }
    }

    return (
        <div className="googleMapLinkAddress">
            <div>
                <Input
                    errorMessage="Please Enter a valid Google map place link."
                    haveError={storeError.generic.includes(STORE_KEYS.googleMapLink)}
                    value={storeValue[STORE_KEYS.googleMapLink]}
                    placeholder="Google map link"
                    onChange={inputValueChangeHandler}
                    label="Please paste your clinic's google map link here :"
                />
            </div>
        </div>
    )
}


export default GoogleMapLinkAddress;