import React from "react";
import { IStoreError, IStoreValues, TStoreErrorDispatcher, TStoreValueDispatcher } from "../types/useStore.type";


interface IContextSchema {
    valueDispatch: TStoreValueDispatcher;
    errorDispatch: TStoreErrorDispatcher;
    resetStoreHandler: () => void;
    observeValuesChange: (cb: (key: string, value: string, error: IStoreError) => void) => () => void;
    observeReset: (cb: () => void) => void;
    store: {
        error: IStoreError,
        values: IStoreValues
    }
}

const CONTEXT_DEFAULT_SCHEMA: IContextSchema = {
    valueDispatch() { },
    errorDispatch() { },
    observeValuesChange() {
        return () => { }
    },
    observeReset() { },
    resetStoreHandler() { },
    store: {
        error: {
            generic: [],
            specific: []
        },
        values: {}
    }
}

export const StoreContext = React.createContext(CONTEXT_DEFAULT_SCHEMA);
