import { ReactNode, useCallback, useState } from "react";
import { StoreContext } from "../context/store";
import { IStore, TObserveValueChangeCb, TStoreErrorDispatcher, TStoreValueDispatcher } from "../types/useStore.type";


const INITIAL_VALUE: IStore = {
    error: {
        generic: [],
        specific: [],
    },
    values: {},
}

const StoreProvider = ({ children }: { children: ReactNode }) => {
    const [store, setStore] = useState<IStore>(INITIAL_VALUE);
    const [connectValueObserver, setConnectedValueObserver] = useState<{ observer: TObserveValueChangeCb }>({ observer: () => { } });
    const [connectedResetObserver, setConnectedResetObserver] = useState<{ observer: () => void }>({ observer: () => { } })


    const valueChangeObserver = useCallback((cb: TObserveValueChangeCb) => {
        setConnectedValueObserver({ observer: cb });
        return unsubscribeValueChange;
    }, []);


    const unsubscribeValueChange = () => {
        setConnectedValueObserver({ observer: () => { } });
    }

    const resetObserver = useCallback((cb: () => void) => {
        setConnectedResetObserver({ observer: cb });
    }, []);



    const innerObserverHelper: TStoreValueDispatcher = (key, value) => {
        connectValueObserver.observer(key, value, store.error);
    }


    const innerResetObserver = () => {
        connectedResetObserver.observer();
    }

    const valueDispatcher: TStoreValueDispatcher = (key, value) => {
        setStore(prev => ({
            ...prev,
            values: {
                ...prev.values,
                [key]: value,
            }
        }));
        innerObserverHelper(key, value);
    }

    const errorDispatcher: TStoreErrorDispatcher = (type, items) => {
        setStore(prev => ({
            ...prev,
            error: {
                ...prev.error,
                [type]: (function detectPassedItemsBaseOnType() {
                    if (typeof items === "string") return [...prev.error[type], items]
                    else if (typeof items === "function") return items(prev.error[type]);
                    else return items; // in this case we just pass the single string value
                })()
            }
        }))
    }

    const resetStoreHandler = () => {
        setStore({
            values: {},
            error: {
                generic: [],
                specific: []
            }
        });
        innerResetObserver();
    }

    return (
        <StoreContext.Provider
            value={{
                store,
                errorDispatch: errorDispatcher,
                valueDispatch: valueDispatcher,
                observeValuesChange: valueChangeObserver,
                observeReset: resetObserver,
                resetStoreHandler,
            }}
        >
            {children}
        </StoreContext.Provider>
    )
}


export default StoreProvider;