import React from "react";
import { IOptions } from "../../types/form.type";

interface Props {
    options?: IOptions[];
    onChange: (selectedItems: string[]) => void;
    value: string[]
}

const Check: React.FC<Props> = ({ value, options, onChange }) => {

    const onOptionSelectHandler = (optionValue: string) => {
        onChange(value.includes(optionValue) ? value.filter(selectedOption => selectedOption !== optionValue) : [...value, optionValue]);
    }

    return (
        <div className="check">
            {
                options?.map((option, i) => (
                    <div className={`check__option ${value.includes(option.value) ? "check__option--selected" : ""}`} key={i}>
                        <div onClick={() => onOptionSelectHandler(option.value)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" fill="none">
                                <path d="M5.893 10.0505C5.78034 10.0696 5.66468 10.0434 5.57125 9.97756L1.44132 7.09118C1.34904 7.02394 1.28682 6.92318 1.26804 6.81055C1.24926 6.69793 1.27541 6.58243 1.34087 6.48888C1.40633 6.39533 1.50588 6.33119 1.61812 6.31025C1.73037 6.28931 1.84634 6.31324 1.94113 6.3769L5.70993 9.01408L11.2342 1.11934C11.2665 1.07123 11.3081 1.03004 11.3565 0.998186C11.4049 0.96633 11.4592 0.944449 11.5162 0.93382C11.5732 0.923191 11.6317 0.924029 11.6883 0.936284C11.745 0.948539 11.7986 0.971965 11.8461 1.00519C11.8936 1.03842 11.934 1.08078 11.9649 1.1298C11.9958 1.17882 12.0166 1.23351 12.0262 1.29068C12.0357 1.34785 12.0337 1.40634 12.0204 1.46274C12.0071 1.51914 11.9826 1.57232 11.9485 1.61916L6.17531 9.87086C6.14235 9.91787 6.10046 9.95793 6.05202 9.98874C6.00359 10.0196 5.94955 10.0405 5.893 10.0505V10.0505Z" fill="#015DAB" stroke="#015DAB" />
                            </svg>
                        </div>
                        <p onClick={() => onOptionSelectHandler(option.value)}>{option.label}</p>
                    </div>
                ))
            }
        </div>
    )
}


export default Check;