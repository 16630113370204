import React from "react";

interface Props {
    onNext: () => void;
    onSkip: () => void;
    canSkip?: boolean;
    loading: boolean;
    disabled: boolean;
    primaryBtnText: string;
}

const FormController: React.FC<Props> = ({ canSkip, primaryBtnText, loading, disabled, onNext, onSkip }) => (
    <div className="formController">
        {
            canSkip && <button disabled={loading || disabled} className="formController__skip" onClick={onSkip}>Skip</button>
        }
        <button disabled={loading || disabled} className="formController__next" onClick={onNext}>{primaryBtnText}</button>
    </div>
)

export default FormController;