const GOOGLE_MAP_REG = /^https?\:\/\/(www\.)?google\.(com|fr|de)\/maps\b/;
const GOOGLE_MAP_COORDS_REG = /@([0-9\.]+),([0-9\.]+),([0-9z]+)/;

const GOOGLE_MAP_SHARABLE_LINK = "https://goo.gl/maps/";

export default function validateGoogleMaoLink(link: string) {
    if ((GOOGLE_MAP_REG.test(link) && GOOGLE_MAP_COORDS_REG.test(link)) || link.startsWith(GOOGLE_MAP_SHARABLE_LINK)) {
        return true;
    } else {
        return false;
    }
}