import React, { ReactNode } from "react";

interface Props {
    onSelect : (methodName : "googleMap" | "manually") => void;
    label : string;
    icon : ReactNode;
    methodKey : "googleMap" | "manually";
}

const AddressBoxSuggest : React.FC<Props> = ({ methodKey , label , icon ,  onSelect }) => {
    return (
        <div onClick={() => onSelect(methodKey)} className="addressBoxSuggest">
            <p>{label}</p>
            {icon}
        </div>
    )
}


export default AddressBoxSuggest;