import React from 'react';
import StoreProvider from './providers/StoreProvider';
import Router from './router/Router';

function App() {
  return (
    <div className="App">
      <StoreProvider>
        <Router />
      </StoreProvider>
    </div>
  );
}

export default App;
