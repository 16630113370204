import React, { useMemo } from "react";
import idGenerator from "../../utils/idGenerator";

interface Props {
    label: string;
    errorMessage?: string;
    inputKey?: string;
    value: string | number;
    placeholder?: string;
    haveError?: boolean;
    onChange: (value: string) => void;
}

const TextArea: React.FC<Props> = ({
    label,
    inputKey,
    value = "",
    haveError,
    placeholder,
    errorMessage = "The field is empty.",
    onChange
}) => {

    const inputIdentifier = useMemo(() => inputKey ? `input-${inputKey}` : idGenerator(), []);


    return (
        <div className={`textarea ${haveError ? "textarea--error" : ""}`}>
            <label htmlFor={inputIdentifier}>{label}</label>
            <textarea
                value={value}
                onChange={e => onChange(e.target.value)}
                placeholder={placeholder}
                id={inputIdentifier} />
            <span className="textarea__errorText">{errorMessage}</span>
        </div>
    )
}


export default TextArea;