import { useContext } from "react";
import { StoreContext } from "../context/store";
import { IUseStoreHookReturn } from "../types/useStore.type";


const useStore = (): IUseStoreHookReturn => {
    const { store, resetStoreHandler, observeValuesChange, observeReset, ...dispatchers } = useContext(StoreContext);

    return {
        storeValue: store.values,
        storeError: store.error,
        resetStoreHandler,
        observeReset,
        observeValuesChange,
        ...dispatchers
    }
}


export default useStore;