export default function detectSocialMediaName(fullAddress: string) {
    const addresses = [
        {
            name: 'linkedin',
            address: "https://www.linkedin.com/",
        },
        {
            name: 'twitter',
            address: "https://twitter.com/",
        },
        {
            name: 'instagram',
            address: "https://www.instagram.com/",
        },
        {
            name: 'facebook',
            address: "https://www.facebook.com/",
        },
    ];

    const foundAddress =  addresses.find(social => fullAddress.startsWith(social.address));
    if(foundAddress) return foundAddress.name;
    else return null;
}