import React, { useMemo } from "react";
import idGenerator from "../../utils/idGenerator";

interface Props {
    label: string;
    errorMessage?: string;
    inputKey?: string;
    value: string | number;
    placeholder?: string;
    haveError?: boolean;
    onChange: (value: string) => void;
}

const Input: React.FC<Props> = ({
    inputKey,
    label,
    placeholder,
    value = "",
    errorMessage = "The field is empty.",
    haveError,
    onChange
}) => {

    const inputIdentifier = useMemo(() => inputKey ? `input-${inputKey}` : idGenerator(), []);

    return (
        <div className={`input ${haveError ? "input--error" : ""}`}>
            <label htmlFor={inputIdentifier}>{label}</label>
            <input
                type="text"
                id={inputIdentifier}
                placeholder={placeholder}
                value={value}
                onChange={e => onChange(e.target.value)} />
            <span className="input__errorText">{errorMessage}</span>
        </div>
    )
}


export default Input;