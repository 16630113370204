import { useState } from "react";
import useStore from "../../hooks/useStore";
import STORE_KEYS from "../../static/STOREKEY";
import { IWorkingHour, IWorkingOptionSchema, TWorkingHourTimeType, TWorkingOptionKeys } from "../../types/workingHours.type";
import TimePicker from "../Base/TimePicker";
import CustomWorkingHours, { DAYS_LIST } from "./CustomWorkingHours";




const WORKING_OPTIONS: IWorkingOptionSchema[] = [
    {
        key: "everyDay",
        label: "Every day",
    },
    {
        key: "everyDayWithoutWeekend",
        label: "Every day without weekend",
    },
    {
        key: "custom",
        label: "Custom",
    },
];


const WorkingHours = () => {
    const [currentWorkingSelectedSchedule, setCurrentWorkingSelectedSchedule] = useState<TWorkingOptionKeys | "">("");
    const { storeValue, valueDispatch } = useStore();

    const changeWorkingHoursSchedule = (schedule: TWorkingOptionKeys | "") => {
        if (schedule === "custom") {
        } else {
            valueDispatch(STORE_KEYS.workingHours, DAYS_LIST.map((_, i) => ({
                day_of_week: i,
                start_time: "00:00",
                end_time: "00:00"

            })));
        }

        if (schedule === "everyDay") valueDispatch(STORE_KEYS.workingOnHoliday, true);
        else valueDispatch(STORE_KEYS.workingOnHoliday, false);
        setCurrentWorkingSelectedSchedule(schedule);
    }

    const resetEntireStageValueIntoContext = () => {
        changeWorkingHoursSchedule("");
        valueDispatch(STORE_KEYS.workingHours, []);
    }


    const changeWorkingHoursTimeHandler = (type: TWorkingHourTimeType, time: string) => {
        const newWhr = storeValue[STORE_KEYS.workingHours].map((whr: IWorkingHour) => ({ ...whr, [type]: time }))
        valueDispatch(STORE_KEYS.workingHours, newWhr);
    }

    return (
        <div className="workingHours">
            <div className={`workingHours__intro ${currentWorkingSelectedSchedule ? "workingHours__intro--hide" : ""}`}>
                <p className="workingHours__introText">Please Select one of the working schedule :</p>
                <div className="workingHours__optionsContainer">
                    {
                        WORKING_OPTIONS.map((option, i) => (
                            <div onClick={() => changeWorkingHoursSchedule(option.key)} className="workingHours__option" key={i}>
                                <p>{option.label}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className={`workingHours__hoursSelection ${currentWorkingSelectedSchedule ? "workingHours__hoursSelection--show" : ""}`}>
                {
                    currentWorkingSelectedSchedule && <div className="workingHours__hoursSelection__container">
                        <div className="workingHours__hoursSelection__header">
                            <div className="workingHours__hoursSelection__header__backTrigger" onClick={resetEntireStageValueIntoContext}>
                                <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M.88,14.09,4.75,18a1,1,0,0,0,1.42,0h0a1,1,0,0,0,0-1.42L2.61,13H23a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1H2.55L6.17,7.38A1,1,0,0,0,6.17,6h0A1,1,0,0,0,4.75,6L.88,9.85A3,3,0,0,0,.88,14.09Z" /></svg>
                                <p>Back</p>
                            </div>
                            <div className="workingHours__hoursSelection__header__selectedOptionName">
                                <p>Selected Working hours : <span>{WORKING_OPTIONS.find(option => option.key === currentWorkingSelectedSchedule)?.label}</span></p>
                            </div>
                        </div>
                        <div className="workingHours__hoursSelection__body">
                            {
                                currentWorkingSelectedSchedule === "custom"
                                    ? <CustomWorkingHours />
                                    : <div className="workingHours__hoursSelection__timePickers">
                                        <TimePicker
                                            placeholder="Start with"
                                            onChange={time => changeWorkingHoursTimeHandler("start_time", time)}
                                        />
                                        <TimePicker
                                            placeholder="End with"
                                            onChange={time => changeWorkingHoursTimeHandler("end_time", time)}
                                        />
                                    </div>
                            }
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}

export default WorkingHours;