import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import Container from "./components/Base/Container";
import Stepper from "./components/Base/Stepper";
import FormController from "./components/FormController";
import FormStep from "./components/FormStep";

import useStore from "./hooks/useStore";
import { steps } from "./formDataset";
import STORE_KEYS from "./static/STOREKEY";
import { sendStageDetails } from "./utils/api";
import validateGoogleMaoLink from "./types/validateGoogleMapLink";
import { useNavigate } from "react-router-dom";


const Form: React.FC = () => {
    const [currentStage, setCurrentStage] = useState(0);
    const { storeValue, storeError, resetStoreHandler, observeValuesChange, observeReset, errorDispatch } = useStore();
    const [sessionId, setSessionId] = useState<number | null>(null);
    const [isInPostingStageDetails, setIsInPostingStageDetails] = useState(false);
    const navigate = useNavigate();

    const [omitStage, setOmitStage] = useState(0);

    const sliderRef = useRef<Slider>(null);


    const handleStepOmitOnCase = (key: string, value: string) => {
        if (key === STORE_KEYS.googleMapLink && validateGoogleMaoLink(value)) setOmitStage(2);
        else if ((omitStage !== 0 && key === STORE_KEYS.googleMapLink)) setOmitStage(0);
    }

    const validateFormInputsHandler = () => {
        let wasAnyError = false;

        const haveFormItemsList = steps[currentStage].items?.length;

        // handle validation base on dynamic inputs generation
        if (haveFormItemsList) {
            steps[currentStage].items?.forEach(item => {
                const currentInputKey = item.inputKey;
                if (item.isRequired && !storeValue[currentInputKey]) {
                    errorDispatch("generic", currentInputKey);
                    wasAnyError = true;
                } else if (storeError.generic.includes(currentInputKey)) {
                    errorDispatch("generic", prev => prev.filter(inputName => inputName !== currentInputKey))
                }
            })
        }

        if (storeError.specific.length) {
            errorDispatch('generic', storeError.specific);
            wasAnyError = true;
        }

        return wasAnyError;
    }

    const slideToNextStageHandler = (nextStageCount: number) => {

        sliderRef.current?.slickGoTo(nextStageCount);
    }


    const nextStagHandler = () => {
        const wasAnyError = validateFormInputsHandler();

        if (!wasAnyError) {

            if (slicedStepsBaseOnOmitProcess[currentStage].isOptional && !Object.entries(storeValue).length) {
                return skipStageHandler()
            }


            setIsInPostingStageDetails(true);
            postStageDetailsHandler()
                .then(res => {
                    if (currentStage === 0 && res.request_id) setSessionId(res.request_id);
                    const nextStageCount = currentStage + 1;
                    setCurrentStage(nextStageCount);
                    const isLastStep = nextStageCount === slicedStepsBaseOnOmitProcess.length;

                    if (!isLastStep) {
                        resetStoreHandler()
                        slideToNextStageHandler(nextStageCount);
                        setIsInPostingStageDetails(false);
                    }else {
                        navigate("/thankyou")
                    }
                });
        };

    }

    const skipStageHandler = () => {
        const nextStageCount = currentStage + 1;
        setCurrentStage(nextStageCount);
        slideToNextStageHandler(nextStageCount);
    }

    const postStageDetailsHandler = () => {
        const stageNumber = currentStage + 1;
        return sendStageDetails(stageNumber, sessionId, storeValue)
            .then(resp => resp)
    }

    useEffect(function observeValueChangeHandler() {
        observeValuesChange((changedKey, value, storeError) => {
            if (value && [...storeError.generic, ...storeError.specific].includes(changedKey)) {
                errorDispatch("generic", prev => prev.filter(inputName => inputName !== changedKey))
            }

            handleStepOmitOnCase(changedKey, value);
        });
    }, [omitStage]);


    useEffect(function observeStoreResetHandler() {
        observeReset(() => setOmitStage(0))
    }, []);


    const slicedStepsBaseOnOmitProcess = steps.slice(0, (steps.length - omitStage));


    const sliderConfig = {
        dots: false,
        infinite: false,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        adaptiveHeight: true,
        verticalSwiping: false,
        swipeToSlide: false,
        swipe: false,
        draggable: false,
        arrows: false,
        easing: "fade-our",
        className: "form__playground__slider"
    }


    return (
        <Container>
            {
                currentStage === slicedStepsBaseOnOmitProcess.length ? null : <>
                    <div className="form">
                        <div className="form__stepper">
                            <Stepper
                                activeStep={currentStage + 1} // because of non-zero-base we increment by one
                                direction="vertical"
                                stepCount={slicedStepsBaseOnOmitProcess.length}
                            />
                        </div>
                        <div className="form__playground">
                            <Slider  {...sliderConfig} ref={sliderRef}>
                                {
                                    slicedStepsBaseOnOmitProcess.map((step, i) => (
                                        <FormStep
                                            component={step?.component}
                                            isActive={currentStage === i}
                                            {...step}
                                            key={i} />
                                    ))
                                }
                            </Slider>
                        </div>
                    </div>
                    <FormController
                        disabled={Boolean(storeError.generic.length)}
                        loading={isInPostingStageDetails}
                        primaryBtnText={currentStage === (slicedStepsBaseOnOmitProcess.length - 1) ? "Done" : "Continue"}
                        onSkip={skipStageHandler}
                        canSkip={slicedStepsBaseOnOmitProcess[currentStage].isOptional}
                        onNext={nextStagHandler}
                    />
                </>
            }
        </Container>
    )
}


export default Form;