import { useEffect, useState } from "react";
import useStore from "../../hooks/useStore";
import STORE_KEYS from "../../static/STOREKEY";
import { IService, ISpecialities } from "../../types/api.type";
import { IOptions } from "../../types/form.type";
import { getServicesList, getSpecialtiesList } from "../../utils/api";
import convertListToOptions from "../../utils/convertListToOptions";
import Input from "../Base/Input";
import MultiSelect from "../Base/MultiSelect";
import Select from "../Base/Select";

const DentalSpecialties = () => {
    const { storeValue, storeError, valueDispatch, errorDispatch } = useStore();
    const [servicesOptions, setServicesOptions] = useState<IOptions[]>([]);
    const [specialitiesOption, setSpecialitiesOption] = useState<IOptions[]>([]);
    const [loading, setLoading] = useState(true);


    useEffect(function getDropdownDatas() {
        Promise.all([
            getServicesList(),
            getSpecialtiesList(),
        ]).then(datas => {
            const [servicesList, specialitiesList]: [IService, ISpecialities] = datas;
            setServicesOptions(convertListToOptions(servicesList));
            setSpecialitiesOption(convertListToOptions(specialitiesList));
            setLoading(false);
        })
    }, []);


    useEffect(function setKeysForValidate() {
        const requiredStageKeysList = [STORE_KEYS.medicalCode, STORE_KEYS.specialties, STORE_KEYS.services];
        errorDispatch('specific', requiredStageKeysList);
    }, []);

    useEffect(function validateInputsInStoreChange() {
        const requiredStageKeysList = [STORE_KEYS.medicalCode, STORE_KEYS.specialties, STORE_KEYS.services];
        errorDispatch('specific', requiredStageKeysList.filter(reqKey => {
            if (storeValue[reqKey]) return false;
            else return true;
        }));
    }, [storeValue]);


    return (
        <div className="dentalSpecialties">
            <Input
                haveError={storeError.generic.includes(STORE_KEYS.medicalCode)}
                value={storeValue[STORE_KEYS.medicalCode]}
                label="Licence Number"
                placeholder="Dental licence number"
                onChange={value => valueDispatch(STORE_KEYS.medicalCode, value)}
            />
            <Select
                isLoading={loading}
                haveError={storeError.generic.includes(STORE_KEYS.specialties)}
                label="Specialties"
                value={storeValue[STORE_KEYS.specialties]}
                placeholder="Please select dental specialties"
                errorMessage="Please select  a dental specialties."
                options={specialitiesOption}
                onChange={values => valueDispatch(STORE_KEYS.specialties, values)}
            />
            <MultiSelect
                isLoading={loading}
                haveError={storeError.generic.includes(STORE_KEYS.services)}
                label="Services"
                value={storeValue[STORE_KEYS.services]}
                placeholder="Please select dental services"
                errorMessage="Please select one or more dental services."
                options={servicesOptions}
                onChange={values => valueDispatch(STORE_KEYS.services, values)}
            />
        </div>
    )
}


export default DentalSpecialties;