import React, { ReactNode, useEffect, useState } from "react";
import RenderFormItem from "../HOC/RenderFormItem";
import { IFormMapper } from "../types/formStep.type";


interface Props extends IFormMapper {
    isActive: boolean;
    component?: ReactNode
}

const FormStep: React.FC<Props> = ({ title, desc, items, component, freeContainer, isActive }) => {
    const [haveToRenderInnerContainer, setHaveToRenderInnerContinuer] = useState(isActive);

    useEffect(function unmountNonActiveInnerContainer() {
        if (!isActive) {
            let timer = setTimeout(() => {
                setHaveToRenderInnerContinuer(false);
                clearTimeout(timer);
            }, 500);
        } else setHaveToRenderInnerContinuer(true)
    }, [isActive]);


    return (
        <div className="formStep">
            <div className="formStep__header">
                <p className="formStep__header__title">{title}</p>
                {
                    desc && <p className="formStep__header__desc">{desc}</p>
                }
            </div>
            <div className="formStep__body">
                <div className={`formStep__body__innerContainer ${freeContainer ? "formStep__body__innerContainer--freeWidth" : ""}`}>
                    {
                        haveToRenderInnerContainer && ((!component && items) ? <RenderFormItem items={items} /> : component)
                    }
                </div>
            </div>
        </div>
    )
}


export default FormStep;