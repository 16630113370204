import React from "react";
import { IOptions } from "../../types/form.type";

interface Props {
    options?: IOptions[];
    inputKey: string;
    label: string;
    haveError?: boolean;
    errorMessage?: string;
    onChange: (string: string) => void;
    value: string;
}

const Radio: React.FC<Props> = ({
    options,
    label,
    value,
    haveError,
    errorMessage = "Please select an item",
    onChange
}) => {

    return (
        <div className={`radio ${haveError ? "radio--error" : ""}`}>
            <div className="radio__innerContainer">
                <p className="radio__label">{label}</p>
                <div className="radio__optionsContainer">
                    {
                        options?.map((option, i) => (
                            <div
                                onClick={() => onChange(option.value)}
                                className={`radio__option ${value === option.value ? "radio__option--selected" : ""}`}
                                key={i}
                            >
                                <span />
                                <p>{option.label}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
            <p className="radio__errorText"><span>{errorMessage}</span></p>
        </div>
    )
}


export default Radio;