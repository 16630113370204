import React, { ReactNode } from "react";

interface Props {
    children: ReactNode
}

const Container: React.FC<Props> = ({ children }) => (
    <div className="container">
        {children}
    </div>
)

export default Container;