import { Point } from "pigeon-maps";
import { useCallback, useEffect, useState } from "react";
import { ENTIRE_MANUALLY_ITEMS } from ".";
import useStore from "../../hooks/useStore";
import STORE_KEYS from "../../static/STOREKEY";
import { IBoundChangeHandlerArgument, ICordinate } from "../../types/map.type";
import { geoCoding, reverseGeocoding } from "../../utils/api";
import createGeoQueryChain from "../../utils/createGeoQuery";
import debounce from "../../utils/debounce";
import Input from "../Base/Input";
import TextArea from "../Base/TextArea";
import MapContainer from "./MapContainer";


export const INPUT_LIST = [
    {
        inputKey: STORE_KEYS.clinicCountry,
        label: "Country",
        placeholder: "Select Country"
    },
    {
        inputKey: STORE_KEYS.clinicState,
        label: "State/Province",
        placeholder: "State/Province"
    },
    {
        inputKey: STORE_KEYS.clinicCity,
        label: "City",
        placeholder: "Select City"
    },
    {
        inputKey: STORE_KEYS.clinicZipCode,
        label: "ZipCode",
        placeholder: "zipCode"
    },
];

const DEFAULT_LOC = {
    lat: 50.879,
    long: 4.6997
}

const ManualAddress = () => {
    const { storeValue, storeError, valueDispatch, errorDispatch } = useStore();
    const [mapBound, setMapBound] = useState<ICordinate>(DEFAULT_LOC);
    const [mapMarkerLocation, setMapMarkerLocation] = useState<ICordinate>(DEFAULT_LOC);
    const [mapZoom, setMapZoom] = useState(3);
    const [boundsChangeManually, setBoundsChangeManually] = useState(true);

    const [isLocationLoading, setIsLocationLoading] = useState(false);

    const reverseGeocodingHandler = (pos: ICordinate) => {
        setIsLocationLoading(true);
        reverseGeocoding(pos)
            .then(({ address: { city, country, state, county }, display_name }) => {
                valueDispatch("country", country);
                valueDispatch("state", state || city);
                valueDispatch("city", city || county);
                valueDispatch("address", display_name);

                setIsLocationLoading(false);
            })
    }



    const getUserCurrentLocation = () => {
        if (window.navigator.geolocation) {
            window.navigator.geolocation
                .getCurrentPosition(({ coords }) => {

                    const loc = { lat: coords.latitude, long: coords.longitude };


                    setMapBound(loc);
                    setBoundsChangeManually(false)
                    if (mapBound.lat !== loc.lat || mapBound.long !== loc.long) {
                        setMapMarkerLocation(loc);
                        reverseGeocodingHandler(loc);
                        zoomBackToCenterPointWithDelay(15);
                    }
                }, undefined, { maximumAge: 0, timeout: 5000, enableHighAccuracy: true });
        }
    }

    const zoomBackToCenterPointWithDelay = (zoomUnit: number) => {
        let timer = setTimeout(function zoomAfterFindLocationHandler() {
            setMapZoom(zoomUnit);
            clearTimeout(timer);
        }, 1000);
    }

    const debouncedReverseGeoCb = useCallback(debounce((pos: ICordinate) => {
        reverseGeocodingHandler(pos);
    }, 500), []);

    const debouncedGeoCb = useCallback(debounce((storeValue) => {
        const { clinicCity, clinicCountry, clinicState } = STORE_KEYS;

        const addressOptions = {
            country: storeValue[clinicCountry],
            city: storeValue[clinicCity],
            state: storeValue[clinicState],
        }

        const queryString = createGeoQueryChain(addressOptions);

        if (queryString) {
            setIsLocationLoading(true);

            geoCoding(queryString)
                .then(data => {
                    if (data.length) {
                        const closetResult = data[0];

                        const closetResultLoc: ICordinate = {
                            lat: +closetResult.lat,
                            long: +closetResult.lon
                        }
                        setMapBound(closetResultLoc);
                        setMapMarkerLocation(closetResultLoc);
                    }
                    setIsLocationLoading(false);
                    const haveToZoomOnSmallPortion = addressOptions.city || addressOptions.state;
                    zoomBackToCenterPointWithDelay(haveToZoomOnSmallPortion ? 13 : 5);
                    setBoundsChangeManually(false)
                });
        }
    }, 700), []);


    const onBoundsChangeHandler = ({ zoom, center: [lat, long], initial }: IBoundChangeHandlerArgument) => {
        if (boundsChangeManually) {
            const newLocation = { lat, long };
            setMapMarkerLocation(newLocation);
            setMapBound(newLocation);
            setMapZoom(zoom);

            // we don't need to get geocoding data in initial auto-execution of this method
            if (!initial) debouncedReverseGeoCb(newLocation);
        }
    }


    const onMarkerPositionChange = (anchor: Point) => {
        const [lat, long] = anchor;
        setMapMarkerLocation({ lat, long });
        reverseGeocodingHandler({ lat, long });
    }


    const addressInputsFieldValueChangeHandler = (key: string, value: string) => {
        valueDispatch(key, value);
        const keysWhiteList = [STORE_KEYS.clinicCountry, STORE_KEYS.clinicCity, STORE_KEYS.clinicState];
        if (keysWhiteList.includes(key)) {
            const clonedStoreValue = JSON.parse(JSON.stringify(storeValue));
            clonedStoreValue[key] = value;
            debouncedGeoCb(clonedStoreValue);
        }
    }

    useEffect(function validateInputsValueHandler() {
        const requiredStageKeysList = ENTIRE_MANUALLY_ITEMS;
        errorDispatch('specific', requiredStageKeysList.filter(reqKey => {
            if (storeValue[reqKey]) return false;
            else return true;
        }));

        return () => {
            errorDispatch("specific", prev => prev.filter(reqKey => !requiredStageKeysList.includes(reqKey)))
        }
    }, [storeValue]);

    return (
        <div className="manualAddress">
            <div className="manualAddress__form">
                <div style={{ width: "100%" }}>
                    <TextArea
                        haveError={storeError.generic.includes(STORE_KEYS.clinicAddress)}
                        value={storeValue[STORE_KEYS.clinicAddress]}
                        onChange={value => valueDispatch(STORE_KEYS.clinicAddress, value)}
                        label="Street Address"
                        placeholder="Address" />
                </div>
                {
                    INPUT_LIST.map((input, i) => (
                        <div key={i} className="manualAddress__form__column">
                            <Input
                                {...input}
                                haveError={storeError.generic.includes(input.inputKey)}
                                value={storeValue[input.inputKey] || ""}
                                onChange={value => addressInputsFieldValueChangeHandler(input.inputKey, value)}
                            />
                        </div>
                    ))
                }
                <div style={{ width: "100%" }}>
                    <Input
                        value={storeValue[STORE_KEYS.clinicPhone]}
                        haveError={storeError.generic.includes(STORE_KEYS.clinicPhone)}
                        label="Office"
                        placeholder="Phone Number"
                        onChange={value => valueDispatch(STORE_KEYS.clinicPhone, value)}
                    />
                </div>
            </div>
            <div className="manualAddress__map">
                <MapContainer
                    zoom={mapZoom}
                    mapBound={mapBound}
                    markerLocation={mapMarkerLocation}
                    isLoading={isLocationLoading}
                    getUserCurrentLocation={getUserCurrentLocation}
                    onBoundsChangeHandler={onBoundsChangeHandler}
                    onMarkerPositionChange={onMarkerPositionChange}
                />
            </div>
        </div>
    )
}


export default ManualAddress;