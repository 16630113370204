import React from "react";
import { Bounds, Draggable, Map as MapLibrary, Point } from "pigeon-maps"
import { ICordinate } from "../../types/map.type";



interface Props {
    isLoading: boolean;
    mapBound: ICordinate;
    zoom: number;
    markerLocation: ICordinate;
    onMarkerPositionChange: (anchor: Point) => void;
    getUserCurrentLocation: () => void;
    onBoundsChangeHandler: ({ center, zoom, bounds, initial, }: { center: [number, number]; bounds: Bounds; zoom: number; initial: boolean; }) => void;
}

const MapContainer: React.FC<Props> = ({ isLoading, mapBound, zoom, markerLocation, onMarkerPositionChange, onBoundsChangeHandler, getUserCurrentLocation }) => {
    return (
        <div className="mapContainer">
            <div className="mapContainer__helpTip">
                <div className="mapContainer__helpTip__infoIconBox">
                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M13.5,6.5a1.5,1.5,0,0,1-3,0A1.5,1.5,0,0,1,13.5,6.5ZM24,19V12.34A12.209,12.209,0,0,0,12.836.028,12,12,0,0,0,.029,12.854C.471,19.208,6.082,24,13.083,24H19A5.006,5.006,0,0,0,24,19ZM12.7,2.024A10.2,10.2,0,0,1,22,12.34V19a3,3,0,0,1-3,3H13.083C7.049,22,2.4,18.1,2.025,12.716A10,10,0,0,1,12.016,2C12.243,2,12.472,2.009,12.7,2.024ZM14,18V12a2,2,0,0,0-2-2H11a1,1,0,0,0,0,2h1v6a1,1,0,0,0,2,0Z" /></svg>
                </div>
                <div>
                    <span>You can use</span>
                    <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M12,6a4,4,0,1,0,4,4A4,4,0,0,0,12,6Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,12Z" /><path d="M12,24a5.271,5.271,0,0,1-4.311-2.2c-3.811-5.257-5.744-9.209-5.744-11.747a10.055,10.055,0,0,1,20.11,0c0,2.538-1.933,6.49-5.744,11.747A5.271,5.271,0,0,1,12,24ZM12,2.181a7.883,7.883,0,0,0-7.874,7.874c0,2.01,1.893,5.727,5.329,10.466a3.145,3.145,0,0,0,5.09,0c3.436-4.739,5.329-8.456,5.329-10.466A7.883,7.883,0,0,0,12,2.181Z" /></svg>
                    <span>icon to use your current location to auto fill inputs fields.</span>
                </div>
            </div>
            <div className="mapContainer__mapLibrary">
                <MapLibrary
                    height={300}
                    onBoundsChanged={onBoundsChangeHandler}
                    center={[mapBound.lat, mapBound.long]}
                    defaultCenter={[mapBound.lat, mapBound.long]}
                    defaultZoom={11}
                    zoom={zoom}
                    minZoom={3}
                    maxZoom={20}

                >
                    <Draggable
                        className="mapContainer__marker"
                        anchor={[markerLocation.lat, markerLocation.long]}
                        onDragEnd={onMarkerPositionChange}>
                        <svg width="50" height="58.62068965517241" viewBox="0 0 61 71" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M52 31.5C52 36.8395 49.18 42.314 45.0107 47.6094C40.8672 52.872 35.619 57.678 31.1763 61.6922C30.7916 62.0398 30.2084 62.0398 29.8237 61.6922C25.381 57.678 20.1328 52.872 15.9893 47.6094C11.82 42.314 9 36.8395 9 31.5C9 18.5709 18.6801 9 30.5 9C42.3199 9 52 18.5709 52 31.5Z" stroke="white" strokeWidth="4"></path><circle cx="30.5" cy="30.5" r="8.5" fill="white" opacity="0.6"></circle></g></svg>
                    </Draggable>
                </MapLibrary>
                {
                    isLoading && <div className="mapContainer__geocodingLoading">
                        <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M12,24A12,12,0,1,1,22.714,6.59a1,1,0,1,1-1.785.9,10,10,0,1,0-.011,9.038,1,1,0,0,1,1.781.908A11.955,11.955,0,0,1,12,24Z" /></svg>
                    </div>
                }
                <div
                    onClick={getUserCurrentLocation}
                    title="Use your current location"
                    className="mapContainer__userLocation"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M12,6a4,4,0,1,0,4,4A4,4,0,0,0,12,6Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,12Z" /><path d="M12,24a5.271,5.271,0,0,1-4.311-2.2c-3.811-5.257-5.744-9.209-5.744-11.747a10.055,10.055,0,0,1,20.11,0c0,2.538-1.933,6.49-5.744,11.747A5.271,5.271,0,0,1,12,24ZM12,2.181a7.883,7.883,0,0,0-7.874,7.874c0,2.01,1.893,5.727,5.329,10.466a3.145,3.145,0,0,0,5.09,0c3.436-4.739,5.329-8.456,5.329-10.466A7.883,7.883,0,0,0,12,2.181Z" /></svg>
                </div>
            </div>
        </div>
    )
}


export default MapContainer;