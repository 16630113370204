import ClinicAddress from "./components/ClinicAddress";
import ClinicMedia from "./components/ClinicMedia";
import DentalSpecialties from "./components/DentalSpecialties";
import WorkingHours from "./components/WorkingHours";
import STORE_KEYS from "./static/STOREKEY";
import { IFormItems } from "./types/form.type";
import { IFormMapper } from "./types/formStep.type";

const stepItems: { [key: number]: IFormItems[] } = {
    0: [
        {
            type: "text",
            inputKey: STORE_KEYS.email,
            label: "Email",
            placeholder: "Name@domain.com",
            isRequired: true,
        },
        {
            type: "text",
            inputKey: STORE_KEYS.fullName,
            label: "Full Name",
            placeholder: "Example: Sarah Melod",
            isRequired: true,
        },
        {
            type: "radio",
            inputKey: STORE_KEYS.gender,
            label: "Gender",
            placeholder: "Example: Sarah Melod",
            isRequired: true,
            options: [
                {
                    label: "Male",
                    value: "1",
                },
                {
                    label: "Female",
                    value: "0",
                },
                {
                    label: "Others",
                    value: "2",
                },
                {
                    label: "Prefer Not to say",
                    value: "3",
                },
            ]
        },
    ],
}

export const steps: IFormMapper[] = [
    {
        title: "Wellcome!",
        desc: "StrAIberry, Sweet Taste of AI in Oral Hygiene",
        items: stepItems['0'],
        isOptional: false,
        freeContainer: false,
    },
    {
        title: "Dental information and specialties",
        desc: "",
        component: <DentalSpecialties />,
        isOptional: false,
        freeContainer: false
    },
    {
        title: "Clinic or Dentist address and contact information",
        desc: "",
        component: <ClinicAddress />,
        isOptional: false,
        freeContainer: true
    },
    {
        title: "Banner Images or/and social media links of your clinic.",
        desc: "Banner Images or/and social media links of your clinic.",
        isOptional: true,
        freeContainer: false,
        component: <ClinicMedia />,
    },
    {
        title: "Working hours",
        desc: "The answer to this section is optional.",
        isOptional: true,
        component: <WorkingHours />,
        freeContainer: true
    },
]