import useStore from "../../hooks/useStore";
import STORE_KEYS from "../../static/STOREKEY";
import ImageUploader from "../Base/ImageUploader";
import Input from "../Base/Input";
import TextArea from "../Base/TextArea";
import SocialMediaInput from "./SocialMediaInput";

const ClinicMedia = () => {
    const { storeValue, valueDispatch } = useStore();

    return (
        <div className="clinicMedia">
            <ImageUploader
                onChange={images => valueDispatch(STORE_KEYS.images, images)}
                label="Upload images of your office or Clinic" />
            <SocialMediaInput />
            <Input
                label="Website Address"
                value={storeValue[STORE_KEYS.clinicWebsite]}
                onChange={value => valueDispatch(STORE_KEYS.clinicWebsite, value)}
                placeholder="Example: www.dentistname.com"
            />
            <TextArea
                value={storeValue[STORE_KEYS.clinicDescription]}
                inputKey="desc"
                label="Description"
                placeholder="Description"
                onChange={value => valueDispatch(STORE_KEYS.clinicDescription, value)}
            />
        </div>
    )
}

export default ClinicMedia;