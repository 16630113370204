import React from "react";
import { IOptions } from "../../types/form.type";

import SelectContainer from "../SelectContainer";

interface Props {
    label: string;
    errorMessage?: string;
    value: string | number;
    placeholder: string;
    haveError: boolean;
    options?: IOptions[];
    isLoading ?: boolean;
    onChange: (value: string) => void;
}

const Select: React.FC<Props> = ({
    label,
    placeholder,
    errorMessage,
    options = [],
    haveError,
    isLoading,
    onChange
}) => {

    return (
        <div className="select">
            <label>{label}</label>
            <SelectContainer
                isLoading={isLoading}
                placeholder={placeholder}
                onSelect={value => onChange(value)}
                haveError={haveError}
                options={options} />
            <p className={`select__errorText ${haveError ? "select__errorText--show" : ""}`}>{errorMessage}</p>
        </div>
    )
}

export default Select;