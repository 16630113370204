import useStore from "../../hooks/useStore";
import STORE_KEYS from "../../static/STOREKEY";
import { IWorkingHour, TWorkingHourTimeType } from "../../types/workingHours.type";
import Check from "../Base/Check";
import TimePicker from "../Base/TimePicker";

export const DAYS_LIST = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
]

const CustomWorkingHours = () => {
    const { storeValue, valueDispatch } = useStore();

    const workingHoursDayList: IWorkingHour[] = storeValue[STORE_KEYS.workingHours] || [];

    const addToWorkingHoursHandler = (dayNumber: number) => {
        const newWhr = [...workingHoursDayList, {
            day_of_week: dayNumber,
            start_time: "00:00",
            end_time: "00:00"
        }];
        valueDispatch(STORE_KEYS.workingHours, newWhr);
    }

    const removeDayWorkingHourHandler = (dayNumber: number) => {
        const newWhr = workingHoursDayList.filter(whr => whr.day_of_week !== dayNumber);
        valueDispatch(STORE_KEYS.workingHours, newWhr)
    }

    const decideOnCheckBoxChangeHandler = (dayNumber: number) => {
        const currentlyHaveThisWorkingHour = workingHoursDayList.find(whr => whr.day_of_week === dayNumber);

        if (currentlyHaveThisWorkingHour) removeDayWorkingHourHandler(dayNumber)
        else addToWorkingHoursHandler(dayNumber);
    }

    const changeDayWorkingHourTimeHandler = (dayNumber: number, type: TWorkingHourTimeType, time: string) => {
        const newWhr = workingHoursDayList
            .map(whr => whr.day_of_week === dayNumber ? ({ ...whr, [type]: time }) : whr);
        valueDispatch(STORE_KEYS.workingHours, newWhr)
    }


    return (
        <div className="customWorkingHours">
            {
                DAYS_LIST.map((day, i) => (
                    <div className="customWorkingHours__dayRow" key={i}>
                        <Check
                            value={workingHoursDayList.find((whr: IWorkingHour) => whr.day_of_week === i) ? [`${i}`] : []}
                            onChange={() => decideOnCheckBoxChangeHandler(i)}
                            options={[{ value: String(i), label: day }]} />
                        {
                            workingHoursDayList.find((whr: IWorkingHour) => whr.day_of_week === i) && <div className="customWorkingHours__timePickersContainer">
                                <TimePicker
                                    placeholder="Start With"
                                    onChange={time => changeDayWorkingHourTimeHandler(i, "start_time", time)} />
                                <TimePicker
                                    placeholder="End With"
                                    onChange={time => changeDayWorkingHourTimeHandler(i, "end_time", time)} />
                            </div>
                        }
                    </div>
                ))
            }
        </div>
    )
}


export default CustomWorkingHours;