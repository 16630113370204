const STORE_KEYS = {
    email: "email",
    fullName: "full_name",
    gender: "gender",
    medicalCode: "medical_code",
    services: "service",
    specialties: "speciality",
    clinicCountry: "country",
    clinicState: "state",
    clinicCity: "city",
    clinicZipCode: "zip_code",
    clinicAddress: "address",
    clinicPhone: 'phone_number',
    googleMapLink: "google_map_link",
    workingHours: "working_hours",
    workingOnHoliday: "work_on_holidays",
    clinicWebsite: "website",
    clinicDescription: "dentist_description",
    images: "images",
    sessionId: "request_id",
}


export default STORE_KEYS;