import React from "react";
import SelectLibrary from "react-select";
import { IOptions } from "../types/form.type";

interface Props {
    options: IOptions[]
    haveError: boolean;
    placeholder: string;
    isMulti?: boolean;
    isLoading?: boolean;
    onSelect: (selectedValue: string) => void
}

const SelectContainer: React.FC<Props> = ({
    options,
    haveError,
    placeholder,
    isMulti = false,
    isLoading = false,
    onSelect
}) => {
    return (
        <SelectLibrary
            isLoading={isLoading}
            controlShouldRenderValue={!isMulti}
            placeholder={placeholder}
            onChange={e => onSelect(e?.value as string)}
            components={{
                IndicatorsContainer: () => <div className="react-select__collapseIcon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                        <path d="M4.42588 4.4336C4.34673 4.43412 4.26827 4.41882 4.19511 4.3886C4.12196 4.35838 4.05558 4.31384 3.99988 4.2576L1.17388 1.4306C1.0613 1.31801 0.998047 1.16531 0.998047 1.0061C0.998047 0.846877 1.0613 0.69418 1.17388 0.581595C1.28646 0.469011 1.43916 0.405762 1.59838 0.405762C1.7576 0.405762 1.9103 0.469011 2.02288 0.581595L4.42288 2.9816L6.82288 0.581595C6.87863 0.525849 6.94481 0.481629 7.01764 0.451459C7.09048 0.42129 7.16854 0.405762 7.24738 0.405762C7.32622 0.405762 7.40428 0.42129 7.47712 0.451459C7.54995 0.481629 7.61613 0.525849 7.67188 0.581595C7.72763 0.637342 7.77185 0.703522 7.80202 0.776358C7.83219 0.849193 7.84771 0.927258 7.84771 1.0061C7.84771 1.08493 7.83219 1.163 7.80202 1.23583C7.77185 1.30867 7.72763 1.37485 7.67188 1.4306L4.84988 4.2576C4.79442 4.31359 4.72836 4.358 4.65557 4.38821C4.58278 4.41843 4.5047 4.43386 4.42588 4.4336Z" fill="#015DAB" />
                    </svg>
                </div>,
            }}
            className={haveError ? "react-select--error" : ""}
            classNamePrefix="react-select"
            menuPortalTarget={document.querySelector('body')}
            options={options} />
    )
}


export default SelectContainer;