import React, { ReactNode } from "react";
import Input from "../components/Base/Input";
import MultiSelect from "../components/Base/MultiSelect";
import Radio from "../components/Base/Radio";
import Select from "../components/Base/Select";

import useStore from "../hooks/useStore";
import { IFormItems } from "../types/form.type";
import { TStoreValueDispatcher } from "../types/useStore.type";


interface Props {
    items: IFormItems[];
}

interface IInputProps extends IFormItems {
    haveError: boolean;
    value: any;
    onChange: (value: string) => void
}

type TFormItemCloneRenderMethod = (key: number, item: IInputProps) => React.ReactNode
interface IFormItemsClone {
    text: TFormItemCloneRenderMethod;
    radio: TFormItemCloneRenderMethod;
    select: TFormItemCloneRenderMethod;
    multiSelect: TFormItemCloneRenderMethod;
}

const FORM_ITEMS_CLONE: IFormItemsClone = {
    text: (key, itemDetails) => <Input key={key} {...itemDetails} />,
    radio: (key, itemDetails) => <Radio {...itemDetails} key={key} />,
    select: (key, itemDetails) => <Select {...itemDetails} key={key} />,
    multiSelect: (key, itemDetails) => <MultiSelect {...itemDetails} key={key} />
}

const RenderFormItem: React.FC<Props> = ({ items }) => {
    const { storeValue, storeError, valueDispatch } = useStore();

    const componentsController = (key: string) => ({
        value: storeValue[key] || "",
        onChange: (value: string) => valueDispatch(key, value)
    });


    return (
        <>
            {
                items.map((item, i) => (
                    <React.Fragment key={i}>
                        {
                            FORM_ITEMS_CLONE[item.type](i, {
                                haveError: storeError.generic.includes(item.inputKey),
                                ...componentsController(item.inputKey),
                                ...item
                            })
                        }
                    </React.Fragment>
                ))
            }
        </>
    )
}


export default RenderFormItem;