import React, { useEffect, useRef, useState } from "react";

interface Props {
    label?: string;
    onChange: (imgData: string[]) => void
}


const ImageUploader: React.FC<Props> = ({ label, onChange }) => {
    const [imgDatas, setImgDatas] = useState<{ value: string, id: number }[]>([]);

    const inputTriggerRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(function observeImgDatasChangeHandler() {
        if (imgDatas.length) {
            onChange(imgDatas.map(img => img.value))
        }
    }, [imgDatas]);


    const triggerUploadInputHandler = () => {
        inputTriggerRef.current?.click();
    }

    const scrollToAddBoxHandler = () => {
        setTimeout(function delayScrollToEndOfContainer() {
            containerRef.current?.scroll({ top: 0, left: containerRef.current.scrollWidth, behavior: "smooth" });
        }, 300);
    }

    const readFileHandler = (fileField: File) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            const uploaded_image = reader.result;
            setImgDatas(prev => [...prev, { id: Date.now(), value: String(uploaded_image) }]);
            scrollToAddBoxHandler()
        });
        reader.readAsDataURL(fileField);
    }

    const inputChangeHandler: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
        if (target.files) {
            readFileHandler(target.files[0]);
            // reset input value imperatively!
            target.value = "";
        }
    }

    const removeUploadedImg = (id: number) => {
        setImgDatas(prev => prev.filter(img => img.id !== id));
    }

    return (
        <div className="imageUploader">
            <p className="imageUploader__label">{label}</p>
            <input
                accept="image/jpeg, image/png, image/jpg"
                onChange={inputChangeHandler}
                ref={inputTriggerRef}
                hidden
                type="file"
            />
            <div
                ref={containerRef}
                onClick={() => !imgDatas.length && triggerUploadInputHandler()}
                className={`imageUploader__container ${imgDatas.length ? "imageUploader__container--active" : ""}`}>
                {
                    !imgDatas.length ?
                        <div className="imageUploader__intro">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M19,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V5A5.006,5.006,0,0,0,19,0ZM5,2H19a3,3,0,0,1,3,3V19a2.951,2.951,0,0,1-.3,1.285l-9.163-9.163a5,5,0,0,0-7.072,0L2,14.586V5A3,3,0,0,1,5,2ZM5,22a3,3,0,0,1-3-3V17.414l4.878-4.878a3,3,0,0,1,4.244,0L20.285,21.7A2.951,2.951,0,0,1,19,22Z" /><path d="M16,10.5A3.5,3.5,0,1,0,12.5,7,3.5,3.5,0,0,0,16,10.5Zm0-5A1.5,1.5,0,1,1,14.5,7,1.5,1.5,0,0,1,16,5.5Z" /></svg>
                            </div>
                            <p>Max size file 5 MB</p>
                        </div>
                        : <>
                            {
                                imgDatas.map((uploadedImg, i) => (
                                    <div key={i} className="imageUploader__uploadedImgItem">
                                        <div onClick={() => removeUploadedImg(uploadedImg.id)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M18,6h0a1,1,0,0,0-1.414,0L12,10.586,7.414,6A1,1,0,0,0,6,6H6A1,1,0,0,0,6,7.414L10.586,12,6,16.586A1,1,0,0,0,6,18H6a1,1,0,0,0,1.414,0L12,13.414,16.586,18A1,1,0,0,0,18,18h0a1,1,0,0,0,0-1.414L13.414,12,18,7.414A1,1,0,0,0,18,6Z" /></svg>
                                        </div>
                                        <img src={uploadedImg.value} alt="uploaded-item" />
                                    </div>
                                ))
                            }
                            <div
                                onClick={triggerUploadInputHandler}
                                className="imageUploader__addImgBox">
                                <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M17,11H13V7a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v4H7a1,1,0,0,0-1,1H6a1,1,0,0,0,1,1h4v4a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V13h4a1,1,0,0,0,1-1h0A1,1,0,0,0,17,11Z" /></svg>
                            </div>
                        </>
                }
            </div>
        </div>
    )
}


export default ImageUploader;