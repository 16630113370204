import React, { useEffect, useRef, useState } from "react";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import ITimePickerTime from "../../types/timePicker.type";
import addZeroStringToNumberUnderTen from "../../utils/addZeroStringToNumberUnderTen";
import convertAmPmTo24Format from "../../utils/convertAmPmTo24Format";
import createHoursWithMinSnap from "../../utils/createHourWithMinuteSnap";
import timeObjToStringConvertor from "../../utils/timeConvertor";


const ClockIconSvg = () => <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,24C5.383,24,0,18.617,0,12S5.383,0,12,0s12,5.383,12,12-5.383,12-12,12Zm0-22C6.486,2,2,6.486,2,12s4.486,10,10,10,10-4.486,10-10S17.514,2,12,2Zm1,16v-6c0-.176-.046-.348-.134-.5l-2-3.464c-.277-.479-.89-.643-1.366-.366-.479,.276-.643,.888-.366,1.366l1.866,3.232v5.732c0,.552,.447,1,1,1s1-.448,1-1Z" /></svg>;
const DownIconSvg = () => <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M12,17.17a5,5,0,0,1-3.54-1.46L.29,7.54A1,1,0,0,1,1.71,6.12l8.17,8.17a3,3,0,0,0,4.24,0l8.17-8.17a1,1,0,1,1,1.42,1.42l-8.17,8.17A5,5,0,0,1,12,17.17Z" /></svg>;



interface Props {
    placeholder?: string;
    onChange: (time: string) => void;
}



const hrsList = createHoursWithMinSnap();


const TimePicker: React.FC<Props> = ({ placeholder, onChange }) => {
    const [timeBaseOn, setTimeBaseOn] = useState<"AM" | "PM">("AM");
    const [time, setTime] = useState<ITimePickerTime>({ hr: 0, min: 0 });

    const [isFocused, setIsFocused] = useState(false);
    const [isDropDownOpened, setIsDropDownOpened] = useState(false);

    const containerRef = useRef<HTMLDivElement>(null);
    const hourInputRef = useRef<HTMLInputElement>(null);


    useOutsideClick(containerRef, function closeHoursDropDownHandler() {
        setIsDropDownOpened(false);
    });


    useEffect(function observeTimeStateChangeHandler() {
        // with this we are essentially reset value on component-mount with default "time" state and "timeBase" state value
        onChange(convertAmPmTo24Format(`${timeObjToStringConvertor(time)}${timeBaseOn.toLowerCase()}`))
    }, [time, timeBaseOn]);

    const focusWithClickOnContainerHandler = () => {
        setIsFocused(true);
        setIsDropDownOpened(true);
        if (!isFocused) hourInputRef.current?.focus();
    }

    const validateNumberRangeHandler = (baseOn: "hr" | "min", value: number): number => {
        if (baseOn === "hr") return value > 12 ? 12 : value;
        return value > 59 ? 59 : value;
    }

    const onTimeInputsValueChange = (baseOn: "hr" | "min", value: string) => {
        setTime(prev => ({
            ...prev,
            [baseOn]: validateNumberRangeHandler(baseOn, Number(value))
        }));
    }

    const onSelectTimeFromHoursDropdownHandler = (timePack: string) => {
        const [hr, min] = timePack.split(":").map(function convertTimeUnits(timeUnit) { return Number(timeUnit) });
        setTime({ hr, min });
    }


    return (
        <div
            ref={containerRef}
            onClick={focusWithClickOnContainerHandler}
            className={`timePicker ${isFocused ? "timePicker--focus" : ""}`}
        >
            <div className="timePicker__container">
                <ClockIconSvg />
                <div className="timePicker__inputContainer">
                    <input
                        ref={hourInputRef}
                        onChange={({ target }) => onTimeInputsValueChange("hr", target.value)}
                        max={12}
                        min={0}
                        value={String(addZeroStringToNumberUnderTen(time.hr))}
                        type="number"
                    />
                    <span>:</span>
                    <input
                        onChange={({ target }) => onTimeInputsValueChange("min", target.value)}
                        max={59}
                        min={0}
                        value={String(addZeroStringToNumberUnderTen(time.min))}
                        type="number"
                    />
                    <p>{timeBaseOn}</p>
                    <div className={`timePicker__placeholder`}>
                        <p>{placeholder}</p>
                    </div>
                </div>
                <DownIconSvg />
            </div>
            <div className={`timePicker__hoursDropDown ${isDropDownOpened ? "timePicker__hoursDropDown--open" : ""}`}>
                <div className="timePicker__hoursDropDown__hours">
                    {
                        hrsList
                            .map((timePack, i) => (
                                <p
                                    className={`timePicker__hoursDropDown__hour ${timeObjToStringConvertor(time) === timePack ? "timePicker__hoursDropDown__hour--selected" : ""}`}
                                    onClick={() => onSelectTimeFromHoursDropdownHandler(timePack)}
                                    key={i}>
                                    {timePack}
                                </p>
                            ))
                    }
                </div>
                <div className="timePicker__hoursDropDown__type">
                    <p onClick={() => setTimeBaseOn("AM")} className={timeBaseOn === "AM" ? "type-active" : ""}>AM</p>
                    <p onClick={() => setTimeBaseOn("PM")} className={timeBaseOn === "PM" ? "type-active" : ""}>PM</p>
                </div>
            </div>
        </div>
    )
}


export default TimePicker;