import React from "react";
import SelectContainer from "../SelectContainer";
import stringConvertor from "../../utils/stringConvertor";
import { IOptions } from "../../types/form.type";

interface Props {
    options?: IOptions[];
    label: string;
    haveError: boolean;
    errorMessage?: string;
    placeholder: string;
    value: string;
    isLoading?: boolean;
    onChange: (string: string) => void;
}

const MultiSelect: React.FC<Props> = ({ placeholder, label, value, errorMessage, options = [], isLoading, haveError, onChange }) => {

    const onSelect = (option: string) => {
        if (!value) onChange(option)
        else {
            const list = stringConvertor.toList(value);
            if (list.includes(option)) onChange(stringConvertor.toString(list.filter(el => el !== option)));
            else onChange(stringConvertor.toString([...list, option]));
        }
    }

    const optionsForRendering = (() => {
        return options.filter(option => {
            if (!value) return true;
            else if (stringConvertor.toList(value).includes(option.value)) return false
            else return true;
        })
    })();


    const removeSelectedItemFromValueHandler = (selectedValue: string) => {
        onChange(stringConvertor.toString(stringConvertor.toList(value).filter(el => el !== selectedValue)))
    }

    return (
        <div className="multiSelect">
            <label>{label}</label>
            <SelectContainer
                isLoading={isLoading}
                isMulti
                placeholder={placeholder}
                onSelect={value => onSelect(value)}
                haveError={haveError}
                options={optionsForRendering}
            />
            <p className={`select__errorText ${haveError ? "select__errorText--show" : ""}`}>{errorMessage}</p>
            <div className="multiSelect__selectedItemsContainer">
                {
                    options.filter(option => stringConvertor.toList(value).includes(option.value)).map((selectedItem, i) => (
                        <div
                            onClick={() => removeSelectedItemFromValueHandler(selectedItem.value)}
                            key={i}
                            className="multiSelect__selectedItem">
                            <p>{selectedItem.label}</p>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                    <line x1="7" y1="0.720773" x2="0.720773" y2="7" stroke="#015DAB" strokeWidth="1.01933" strokeLinecap="round" />
                                    <line x1="0.726483" y1="1" x2="7" y2="7.27352" stroke="#015DAB" strokeWidth="1.0274" strokeLinecap="round" />
                                </svg>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}


export default MultiSelect;