import React from "react";

interface Props {
    activeStep: number;
    stepCount: number;
    direction: "horizontal" | "vertical";
}


const Stepper: React.FC<Props> = ({ activeStep, stepCount, direction = "vertical" }) => {

    return (
        <div className={`stepper stepper--${direction}`}>
            {
                new Array(stepCount)
                    .fill("")
                    .map((_, i) => (
                        <React.Fragment key={i}>
                            <div className={`stepper__item ${activeStep >= (i + 1) ? "stepper__item--active" : ""}`}>
                                <span>{i + 1}</span>
                                <div className="stepper__item__filler">
                                    <div></div>
                                </div>
                            </div>
                            {
                                i < (stepCount - 1) && <div className={`stepper__mobileDivider ${activeStep >= (i + 1) ? "stepper__mobileDivider--active" : ""}`} />
                            }
                        </React.Fragment>
                    ))
            }
        </div>
    )
}


export default Stepper;