import axios from "axios";
import { IReversGeocodingArguments, IReversGeocodingResponse, ISubmitDentistRequestResponse } from "../types/api.type";

const NOMINATIM_BASE_URL = "https://nominatim.openstreetmap.org";
const STRAIBERRY_BASE_URL = "https://api.dev.straiberry.com/api/";

export function reverseGeocoding({ lat, long }: IReversGeocodingArguments) {
    return axios.get(`${NOMINATIM_BASE_URL}/reverse?format=json&lat=${lat}&lon=${long}&zoom=18&addressdetails=1`)
        .then(({ data }) => data as IReversGeocodingResponse);
}


export function sendStageDetails(stageNumber: number, sessionId: number | null, details: any) {
    return axios.post(`${STRAIBERRY_BASE_URL}v1/ngo/submit-dentist-request-english`, {
        step: stageNumber,
        ...(sessionId ? { request_id: sessionId } : undefined),
        ...details,
    }).then(({ data }) => data as ISubmitDentistRequestResponse)
}


export function geoCoding(query: string) {
    return axios.get(`${NOMINATIM_BASE_URL}/search?q=${query}&format=jsonv2`)
        .then(({ data }) => data as IReversGeocodingResponse[])
}


export function getServicesList() {
    return axios.get(`${STRAIBERRY_BASE_URL}v1/ngo/services?language=EN`)
        .then(({ data }) => data);
}


export function getSpecialtiesList() {
    return axios.get(`${STRAIBERRY_BASE_URL}v1/ngo/specialities?language=EN`)
        .then(({ data }) => data)
}