export default function convertAmPmTo24Format(str: string) {
    str = String(str).toLowerCase().replace(/\s/g, '');
    var has_am = str.indexOf('am') >= 0;
    var has_pm = str.indexOf('pm') >= 0;
    str = str.replace('am', '').replace('pm', '');
    if (str.indexOf(':') < 0) str = str + ':00';
    if (has_am) str += ' am';
    if (has_pm) str += ' pm';
    var d = new Date("1/1/2011 " + str);
    var doubleDigits = function (n: number) {
        return (n < 10) ? "0" + n : String(n);
    };
    return doubleDigits(d.getHours()) + ':' + doubleDigits(d.getMinutes());
}
